import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import perfilImage1 from '../images/profile/1A.png';
import perfilImage2 from '../images/profile/1B.png';
import perfilImage3 from '../images/profile/2A.png';
import perfilImage4 from '../images/profile/2B.png';
import perfilImage5 from '../images/profile/3A.png';
import perfilImage6 from '../images/profile/3B.png';
import perfilImage7 from '../images/profile/4A.png';
import perfilImage8 from '../images/profile/4B.png';
import perfilImage9 from '../images/profile/5A.png';
import perfilImage10 from '../images/profile/5B.png';
import perfilImage11 from '../images/profile/6A.png';
import perfilImage12 from '../images/profile/6B.png';
import perfilImage13 from '../images/profile/7A.png';
import perfilImage14 from '../images/profile/7B.png';
import arrowl from '../images/arrow_l.png';
import arrowr from '../images/arrow_r.png';
import male from '../images/male.png';
import female from '../images/female.png';
import TwoButtons from '../components/twoButtons';



const PerfilComponent = () => { 
    const [profileInfo, setProfileInfo] = useState({
        username: '',
        average: 0,
        wines: 0,
        profile: 0,
        id: 0
    });
        const info = [
            {
                titulo: "The Wolf of Wine Street",
                descripcion: "El vino comenzó siendo algo para celebrar un buen negocio o una venta importante. Quizás por eso tu mente lo asocia a la adrenalina que te da cerrar una gran operación. Antes pensabas en el bonus, ahora solo piensas con qué vino vas a celebrar. Desde el principio brindaste con lo mejor de lo mejor pero, ¿eres capaz de reconocer un vino de 10€ de uno de 200€? ¡Demuestra que no solo catas etiquetas!"
            },
            {
                titulo: "The Wolf of Wine Street",
                descripcion: "El vino comenzó siendo algo para celebrar un buen negocio o una venta importante. Quizás por eso tu mente lo asocia a la adrenalina que te da cerrar una gran operación. Antes pensabas en el bonus, ahora solo piensas con qué vino vas a celebrar. Desde el principio brindaste con lo mejor de lo mejor pero, ¿eres capaz de reconocer un vino de 10€ de uno de 200€? ¡Demuestra que no solo catas etiquetas!"
            },
            {
                titulo: "Real Wine Gansta",
                descripcion: "Los días del 'gin and juice' han quedado atrás para ti. Desde que empezaste a pedir burbujas en los reservados del club, no ha habido vuelta atrás. Como suele decirse, 'once you go blanc de blancs, you never go back'. Tu paladar se ha acostumbrado a esas burbujas afiladas, pero no te equivoques, el Champagne es solo una droga puente hacia el resto de vinos."
            },
            {
                titulo: "Real Wine Gansta",
                descripcion: "Los días del 'gin and juice' han quedado atrás para ti. Desde que empezaste a pedir burbujas en los reservados del club, no ha habido vuelta atrás. Como suele decirse, 'once you go blanc de blancs, you never go back'. Tu paladar se ha acostumbrado a esas burbujas afiladas, pero no te equivoques, el Champagne es solo una droga puente hacia el resto de vinos."
            },
            {
                titulo: "Hípster Winelover",
                descripcion: "Sabes que el mundo del vino es muy grande y te gusta encontrar esas joyas ocultas que casi nadie conoce. Eres un explorador, un cazador en busca de los más exóticos y extraños elixires. Vives en un ático en Malasaña donde te rodean vinotecas y bares en los que medir tu paladar. Y es hora de demostrarle al mundo que eres el mejor, aunque eso tu ya lo sabes."
            },
            {
                titulo: "Hípster Winelover",
                descripcion: "Sabes que el mundo del vino es muy grande y te gusta encontrar esas joyas ocultas que casi nadie conoce. Eres una exploradora, una cazadora en busca de los más exóticos y extraños elixires. Vives en un ático en Malasaña donde te rodean vinotecas y bares en los que medir tu paladar. Y es hora de demostrarle al mundo que eres la mejor, aunque eso tu ya lo sabes."
            },
            {
                titulo: "Winebro Tryhard",
                descripcion: "El vino es tu pasión y harás todo lo que este en tu mano para ser el mejor catador y aplastar a tus rivales. Entrenas todos los días en el arte junto a tus winebros y no pararás hasta conseguir el reconocimiento de todo el mundo. No solo bebes vino, lo estudias, lo sientes, no puedes fallar. “No taste, no gain”. No importa el vino, sino identificar cual es. Has nacido para la competición."
            },
            {
                titulo: "Winequeen Tryhard",
                descripcion: "El vino es tu pasión y harás todo lo que este en tu mano para ser la mejor catadora y aplastar a tus rivales. Entrenas todos los días en el arte junto a tus winequeens y no pararás hasta conseguir el reconocimiento de todo el mundo. No solo bebes vino, lo estudias, lo sientes, no puedes fallar. “No taste, no gain”. No importa el vino, sino identificar cual es. Has nacido para la competición."
            },
            {
                titulo: "Grunge Wine Soul",
                descripcion: "Este mundo se va a la mierda y lo sabes. Estas harto del postureo y de los esnobs que han llegado al mundo del vino. Es hora de patear sus blandos culos. Te criaste en la calle bebiendo en los antros más chungos. Has bebido de todo: vinos Espartacos, picados y con corcho sin inmutarte.  Pero toda esa experiencia previa te ha servido para detectar perfectamente los fallos de un vino, hasta llegar a ser un auténtico maestro de la cata. A ti no te la dan con queso. Eres un crack seleccionando calidad-precio, si vale más de 10€ está sobrevalorado."
            },
            {
                titulo: "Grunge Wine Soul",
                descripcion: "Este mundo se va a la mierda y lo sabes. Estas harta del postureo y de los esnobs que han llegado al mundo del vino. Es hora de patear sus blandos culos. Te criaste en la calle bebiendo en los antros más chungos. Has bebido de todo: vinos Espartacos, picados y con corcho sin inmutarte. Pero toda esa experiencia previa te ha servido para detectar perfectamente los fallos de un vino, hasta llegar a ser una auténtica maestra de la cata. A ti no te la dan con queso. Eres una crack seleccionando calidad-precio, si vale más de 10€ está sobrevalorado."
            },
            {
                titulo: "Wine Joker",
                descripcion: "Hola ¿qué tal? ¿Tiene alcohol? ¡Póngame 2! Reconozcámoslo, tienes un don, y ese don se acrecienta cuando estás pedo. No sabes por qué, pero cuando bebes tu olfato y paladar se afilan como una katana y además te lo pasas en grande. Es tu oportunidad para mostrar tu don al mundo y además pegarte una buena fiesta. Que empiece la competición."
            },
            {
                titulo: "Wine Joker",
                descripcion: "Hola ¿qué tal? ¿Tiene alcohol? ¡Póngame 2! Reconozcámoslo, tienes un don, y ese don se acrecienta cuando estás pedo. No sabes por qué, pero cuando bebes tu olfato y paladar se afilan como una katana y además te lo pasas en grande. Es tu oportunidad para mostrar tu don al mundo y además pegarte una buena fiesta. Que empiece la competición."
            },
            {
                titulo: "Zero-Zero",
                descripcion: "Tu aspecto desafiante, con el cabello teñido de colores vibrantes y tu estilo distintivo, contrasta con la imagen típica de un amante del vino.  Pero tú no eres el consumidor de vino típico: mientras otros hablan de variedades, regiones o técnicas de elaboración, tú mencionas términos como 'zero ppm', 'VA' o 'Brett'.  Si tu cuñado ha oído hablar de algún vino que te gusta es que es demasiado mainstream, al fin y al cabo, ¡cuando haces pop ya no haces punk! "
            },
            {
                titulo: "Zero-Zero",
                descripcion: "Tu aspecto desafiante, con el cabello teñido de colores vibrantes y tu estilo distintivo, contrasta con la imagen típica de un amante del vino.  Pero tú no eres el consumidor de vino típico: mientras otros hablan de variedades, regiones o técnicas de elaboración, tú mencionas términos como 'zero ppm', 'VA' o 'Brett'.  Si tu cuñado ha oído hablar de algún vino que te gusta es que es demasiado mainstream, al fin y al cabo, ¡cuando haces pop ya no haces punk! "
            },
        ]
    const images = [
        perfilImage1, perfilImage2, perfilImage3, perfilImage4,
        perfilImage5, perfilImage6, perfilImage7, perfilImage8,
        perfilImage9, perfilImage10, perfilImage11, perfilImage12,
        perfilImage13, perfilImage14
    ];

    useEffect(() => {
        const userLogged = localStorage.getItem('userLogged');
        if (userLogged) {
            const userInfo = JSON.parse(userLogged);
            setProfileInfo(userInfo);
            setActual(userInfo.profile);
        }
    }, []);

    const [showInfo, setShowInfo] = useState(false);
    const [actual, setActual] = useState(0);
    const navigate = useNavigate();
    
    const cambiarImagen = (direccion) => {
        console.log(actual);
        let nuevoActual = parseInt(actual) + direccion;
        if (nuevoActual > 13) {
            nuevoActual = nuevoActual % 14; // Si es mayor que 13, se asegura de que esté en el rango [0, 13]
        } else if (nuevoActual < 0) {
            nuevoActual = (nuevoActual + 14) % 14; // Si es menor que 0, se asegura de que esté en el rango [0, 13]
        }
        setActual(nuevoActual);
        console.log(actual);
    };

    const handleConfirm = () => {
        const xhr = new XMLHttpRequest();
        var data = new FormData();
        data.append('profile', actual);  // Enviar el valor de `actual` como `profile`

        xhr.open("POST", `${process.env.REACT_APP_API_URL}/users/${profileInfo.id}`, true);
        //xhr.open("POST", `http://wineapi/api/users/${profileInfo.id}`, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    console.log('Actualización exitosa:', this.responseText);
                    const user = JSON.parse(this.responseText);
                    localStorage.setItem('userLogged', JSON.stringify({ username: user.username, profile: user.profile, wines: user.wines, average: user.average, id: user.id, email: user.email }));
                    navigate('/');  // Redirigir a la página de perfil o a otra según convenga
                } else {
                    console.error('Error al actualizar:', this.status, this.responseText);
                }
            }
        };
        xhr.send(data);
    };


  return (
    <div className="body-grey">
      <div className="user-container">

              <div className="profile-image"><img src={images[actual]} /></div>
              <div className="profile-info" style={{ display: showInfo ? 'none' : 'block' }}>
          <h4>WINE POLICE DEPT.</h4>
                  <div className="row">
                      <div className="col-6">{profileInfo.username}<br></br>Ranking del mes: XX<br></br>Media : {profileInfo.average}<br></br>Vinos catados : {profileInfo.wines}</div>
                      <div className="col-2">WINEGANGS:</div>
                      <div className="col-4"><br></br></div>
                  </div>
              </div>
              <div className="gender-buttons">
                  <button className={`button-male ${actual % 2 === 0 ? 'inverted' : ''}`} onClick={() => cambiarImagen(-1)}>
                      <img src={male} alt="Male" />
                  </button>
                  <button className={`button-female ${actual % 2 !== 0 ? 'inverted' : ''}`} onClick={() => cambiarImagen(1)}>
                      <img src={female} alt="Female" />
                  </button>
              </div>
        <button className="button-back" onClick={() => cambiarImagen(-2)}><img src={arrowl} alt="Primero" className=""/></button>
        <button className="button-next" onClick={() => cambiarImagen(2)}><img src={arrowr} alt="Primero" className=""/></button>
              <button className="button-toggle  button-left" onClick={() => setShowInfo(!showInfo)}>Informaci&oacute;n</button>
              <button className="button-edit" onClick={handleConfirm} >Confirmar</button>
              <div className="gender-buttons">
              </div>

              <div className="informacion" style={{ display: showInfo ? 'block' : 'none' }}>
            {/* Contenido del div "informacion" */}
                  {info[actual] && (  // Verifica que info[actual] no es undefined
                      <>
                          <h3>{info[actual].titulo}</h3>
                          <p>{info[actual].descripcion}</p>
                      </>
                  )}
                  <button className="button-close" onClick={() => setShowInfo(!showInfo)}></button>
          </div>

          </div>
          <TwoButtons />
          <button className="button-logout" ><Link to={`/edit-data`}>Editar datos</Link></button>
    </div>
  )
}

export default PerfilComponent;