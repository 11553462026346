import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const handleRegisterClick = () => {
        navigate('/login');  // Función para navegar a /register
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous errors
        setErrors({});

        const passwordRegex = /^.{8,}$/;


        // Check username length
        if (formData.username.length < 2 || formData.username.length > 15) {
            setErrors(errors => ({ ...errors, username: "El usuario debe tener entre 2 y 15 caracteres" }));
            return;
        }


        if (formData.email !== formData.confirmEmail) {
            setErrors(errors => ({ ...errors, confirmEmail: "Los correos electrónicos no coinciden" }));
            return;
        }

        
        if (!/\S+@\S+\.\S+/.test(formData.email)) {
            setErrors(errors => ({ ...errors, email: "Formato de correo electrónico inválido" }));
            return;
        }

        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            setErrors(errors => ({ ...errors, password: "Las contraseñas no coinciden" }));
            return;
        }

        if (!passwordRegex.test(formData.password)) {
            setErrors(errors => ({ ...errors, password: "La contraseña debe tener al menos 8 caracteres" }));
            return;
        }
        if (!formData.acceptPrivacy) {
            setErrors((errors) => ({
                ...errors,
                acceptPrivacy: 'Debes aceptar la política de privacidad',
            }));
            return;
        }
        

        try {
            let xhr = new XMLHttpRequest();
            var data = new FormData();
            data.append('username', formData.username);
            data.append('email', formData.email);
            data.append('password', formData.password);

            let url = `${process.env.REACT_APP_API_URL}/users`;
            //let url = 'http://wineapi/api/users';
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 200) {
                        const user = JSON.parse(this.responseText);
                        localStorage.setItem('userLogged', JSON.stringify({ username: user.username, profile: user.profile, wines: user.wines, average: user.average, id: user.id }));
                        navigate('/edit-user');
                    } else if (this.status === 409) {
                        const error = JSON.parse(this.responseText);
                        setErrors(errors => ({ ...errors, [error.field]: error.error }));
                    }
                }
            }
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <div className="winegangs-container">
            <div className="register-container">
                <h2>Nuevo usuario</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-line">
                        <label htmlFor="username">Usuario</label>
                        <input type="text" id="username" value={formData.username} onChange={handleChange} placeholder="Juan" />
                        
                    </div>
                    {errors.username && <p className="error">{errors.username}</p>}
                    <div className="form-line">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" value={formData.email} onChange={handleChange} placeholder="juan@example.com" />
                    </div>
                    <div className="form-line">
                        <label htmlFor="confirmEmail">Repetir Email</label>
                        <input type="email" id="confirmEmail" value={formData.confirmEmail} onChange={handleChange} placeholder="juan@example.com" />
                    </div>
                    {errors.email && <p className="error">{errors.email}</p>}
                    {errors.confirmEmail && <p className="error">{errors.confirmEmail}</p>}
                    <div className="form-line">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" id="password" value={formData.password} onChange={handleChange} placeholder="********" />
                    </div>
                    <div className="form-line">
                        <label htmlFor="confirmPassword">Repetir Contraseña</label>
                        <input type="password" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="********" />
                    </div>
                    {errors.password && <p className="error">{errors.password}</p>}
                    <div className="privace-poli">
                        
                            <input
                                type="checkbox"
                                id="acceptPrivacy"
                                checked={formData.acceptPrivacy}
                                onChange={handleChange}
                            /><span>
                            Acepto la  {' '}
                            <a
                                href="https://winegang.es/politica-de-privacidad/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                 política de privacidad
                            </a>
                        </span>
                    </div>
                    {errors.acceptPrivacy && (
                        <p className="error">{errors.acceptPrivacy}</p>
                    )}
                    <p className="link" onClick={handleRegisterClick} style={{ cursor: "pointer", color: "blue" }}>Login</p>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}
