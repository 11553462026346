import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import perfilImage1 from '../images/profile/1A.png';
import perfilImage2 from '../images/profile/1B.png';
import perfilImage3 from '../images/profile/2A.png';
import perfilImage4 from '../images/profile/2B.png';
import perfilImage5 from '../images/profile/3A.png';
import perfilImage6 from '../images/profile/3B.png';
import perfilImage7 from '../images/profile/4A.png';
import perfilImage8 from '../images/profile/4B.png';
import perfilImage9 from '../images/profile/5A.png';
import perfilImage10 from '../images/profile/5B.png';
import perfilImage11 from '../images/profile/6A.png';
import perfilImage12 from '../images/profile/6B.png';
import perfilImage13 from '../images/profile/7A.png';
import perfilImage14 from '../images/profile/7B.png';
import trophy from '../images/trofeo.png';
import arrowl from '../images/arrow_l.png';
import arrowr from '../images/arrow_r.png';
import TwoButtons from '../components/twoButtons';



const PerfilComponent = () => {
    const [profileInfo, setProfileInfo] = useState({
        username: '',
        average: 0.00,
        wines: 0,
        profile: 0,
        id: 0,
        ranking: 0,
    });
    const navigate = useNavigate();

    useEffect(() => {
        // Cargar la informaci�n del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                username: userInfo.username,
                average: userInfo.average || 0.00,
                wines: userInfo.wines || 0,
                id: userInfo.id || 0,
                ranking: userInfo.ranking || 0,
                profile: userInfo.profile || 'default'
            });
        }
    }, []);

    const images = [
        perfilImage1, perfilImage2, perfilImage3, perfilImage4,
        perfilImage5, perfilImage6, perfilImage7, perfilImage8,
        perfilImage9, perfilImage10, perfilImage11, perfilImage12,
        perfilImage13, perfilImage14
    ];
    const { id } = useParams();
    const [userData, setUserData] = useState({ profile: 0, average: 0.00 });
    const fetchUserData = () => {
        console.log(id);
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/user/${id}`;
        //const url = `http://wineapi/api/user/${id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const userData = JSON.parse(this.responseText);
                    setUserData(userData);
                    console.log("Datos del usuario:", userData);
                } else {
                    console.error("Error al obtener los datos del usuario:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    function clearLocalStorage() {
        localStorage.clear();
        console.log('Local storage cleared');
        navigate('/login');
    }

    return (
        <div className="body-grey">
            <div className="user-container">

                <div className="profile-image"><img src={images[userData.profile]} alt={images[userData.profile]} /></div>
                <div className={`profile-info`}>
                    <h4>WINE POLICE DEPT.</h4>
                    <div className="row">
                        <div className="col-6">{userData.username}<br></br>Ranking del mes: {userData.ranking}<br></br>Nivel : {userData.average.toFixed(2) + '%'}<br></br>Vinos catados : {userData.wines}</div>
                        <div className="col-2">WINEGANG:</div>
                        <div className="col-4">
                            {userData.winegangs && userData.winegangs.map((winegang, index) => (
                                <React.Fragment key={winegang.id}>
                                    {index > 0 && <br />} {/* A�ade un <br /> solo entre elementos, no antes del primero */}
                                    {winegang.winegang_name}
                                </React.Fragment>
                            ))}</div>
                    </div>
                    {userData.ranking === 1 && (
                        <div className="trofeos" >1</div>
                    )}
                </div>
                <button className="button-toggle button-left"><Link to={`/stats/${userData.id}`}>Estad&iacute;sticas</Link></button>
                {userData.id === profileInfo.id && (
                    <button className="button-edit" ><Link to="/edit-user">Editar</Link></button>
                )}
                {userData.id === profileInfo.id && (
                    <button className="button-logout" onClick={clearLocalStorage}>Salir</button>
                )}


            </div>
            <TwoButtons />
        </div>
    )
}

export default PerfilComponent;