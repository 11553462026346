import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Profile from '../components/profileRed';
import TwoButtons from '../components/twoButtons';
import notify from '../images/notify.png';

const sortByMonth = (users) => {
    users.sort((a, b) => b.month - a.month);
};

const sortByAverage = (users) => {
    users.sort((a, b) => b.average - a.average);
};
const sortByWines = (users) => {
    users.sort((a, b) => {
        if (b.wines !== a.wines) {
            return b.wines - a.wines;
        } else if (b.average !== a.average) {
            return b.average - a.average;
        } else {
            return b.month - a.month;
        }
    });
};
export default function Crear() {
    const [profileInfo, setProfileInfo] = useState({ id: 0 });
    const [notifications, setNotifications] = useState({ invitations: [], winegangs: [] });
    const [winegangData, setWinegangData] = useState({ users: [] });
    const [selectedButton, setSelectedButton] = useState('Mensual');
    const [isPopupVisible, setPopupVisible] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Cargar la información del usuario desde localStorage
        const storedUserInfo = localStorage.getItem('userLogged');
        if (storedUserInfo) {
            const userInfo = JSON.parse(storedUserInfo);
            console.log(userInfo);
            setProfileInfo({
                id: userInfo.id || 0,
            });
        }
    }, []);

    const fetchWinegangData = () => {
        console.log(id);
        const xhr = new XMLHttpRequest();
        const url = `${process.env.REACT_APP_API_URL}/winegang/${id}`;
        //const url = `http://wineapi/api/winegang/${id}`;

        xhr.open("GET", url, true);
        xhr.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    const winegangData = JSON.parse(this.responseText);

                    // Ordenar los usuarios por month inicialmente
                    sortByMonth(winegangData.users);

                    setWinegangData(winegangData);
                    console.log("Datos de la winegang:", winegangData);
                } else {
                    console.error("Error al obtener los Datos de la winegang:", this.status, this.responseText);
                }
            }
        };
        xhr.send();
    };

    const fetchNotifData = () => {
        if (profileInfo && profileInfo.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/hasNotifications/${profileInfo.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const data = JSON.parse(xhr.responseText);
                    setNotifications({
                        invitations: data.invitations || [],
                        winegangs: data.winegangs || []
                    });
                    console.log(data);
                }
            };
            xhr.send();
        }
    };

    useEffect(() => {
        if (profileInfo && profileInfo.id) {
            fetchWinegangData();
            fetchNotifData();
        }
    }, [profileInfo]);

    useEffect(() => {
        let sortedUsers = [...winegangData.users];
        if (selectedButton === 'Mensual') {
            sortByMonth(sortedUsers);
        } else if (selectedButton === 'General') {
            sortByAverage(sortedUsers);
        } else if (selectedButton === 'Wines') {
            sortByWines(sortedUsers);
        }
        setWinegangData({ ...winegangData, users: sortedUsers });
    }, [selectedButton]);

    const showPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    const redirectToUserPage = (userId) => {
        navigate(`/user/${userId}`);
    };

    const redirectToInvite = (winegangId) => {
        navigate(`/gangs/invitar/${winegangId}`);
    };

    const redirectToDemands = (winegangId) => {
        navigate(`/gangs/solicitudes/${winegangId}`);
    };

    const redirectToEdit = () => {
        navigate(`/gangs/editar/${winegangData.winegang.id}`);
    };

    const deleteWinegangUser = (user_id) => {
        if (user_id && winegangData.winegang.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/deleteWinegangUser/${user_id}/${winegangData.winegang.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                        fetchWinegangData();
                    } else {
                        console.error("Failed to fetch data:", xhr.status, xhr.responseText);
                    }
                }
            };
            xhr.send();
        } else {
            console.error("Invalid or missing user ID or winegang ID");
        }
    };

    const createDemand = () => {
        try {
            let xhr = new XMLHttpRequest();
            let data = new FormData();
            data.append('user_id', profileInfo.id);
            data.append('winegang_id', winegangData.winegang.id);

            let url = `${process.env.REACT_APP_API_URL}/demand`;
            xhr.open("POST", url, true);
            xhr.onreadystatechange = function () {
                if (this.readyState === 4) {
                    if (this.status === 201) {
                        console.log("Success:", this.responseText);
                    } else {
                        console.error("Server responded with status:", this.status);
                    }
                }
            };
            xhr.send(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const leaveWinegangUser = () => {
        if (profileInfo.id && winegangData.winegang.id) {
            const xhr = new XMLHttpRequest();
            const url = `${process.env.REACT_APP_API_URL}/deleteWinegangUser/${profileInfo.id}/${winegangData.winegang.id}`;
            xhr.open("GET", url, true);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        console.log(data);
                        navigate('/gangs');
                    } else {
                        console.error("Failed to fetch data:", xhr.status, xhr.responseText);
                    }
                }
            };
            xhr.send();
        } else {
            console.error("Invalid or missing user ID or winegang ID");
        }
    };

    const shouldShowNotification = () => {
        console.log(Number(id));
        return notifications.winegangs.some(winegang => Number(winegang.winegang_id) === Number(id));
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    return (
        <div className="winegangs-container">
            <Profile />
            <div className="gang-container">
                <h3>{winegangData.winegang ? winegangData.winegang.winegang_name : 'Cargando...'}</h3>
                <div className="description">{winegangData.winegang ? winegangData.winegang.text : 'Cargando...'}</div>
                {Number(winegangData.admin_user_id) === Number(profileInfo.id) && (
                    <div className="first-buttons">
                        <button onClick={() => redirectToEdit()}>Editar</button>
                        <button onClick={() => showPopup()}>Abandonar</button>
                    </div>
                )}
                {Number(winegangData.admin_user_id) === Number(profileInfo.id) && (
                    <div className="first-buttons">
                        <button onClick={() => redirectToInvite(winegangData.winegang.id)}>Invitar</button>
                        <button onClick={() => redirectToDemands(winegangData.winegang.id)}>
                            <img src={notify} alt="Icono" className="notify-gang-image" style={{ display: shouldShowNotification() ? 'block' : 'none' }} />
                            Solicitudes
                        </button>
                    </div>
                )}
                {winegangData.users && winegangData.users.length > 0 && winegangData.users.some(user => Number(user.id) === Number(profileInfo.id)) && Number(winegangData.admin_user_id) !== Number(profileInfo.id) && (
                    <div className="first-buttons">
                        <button onClick={() => leaveWinegangUser()}>Abandonar</button>
                    </div>
                )}
                {(!winegangData.users || !winegangData.users.some(user => Number(user.id) === Number(profileInfo.id))) && (
                    <div className="first-buttons">
                        <button onClick={() => createDemand()}>Solicitar</button>
                    </div>
                )}
                {winegangData.users && winegangData.users.length > 0 && Number(winegangData.admin_user_id) !== Number(profileInfo.id) && (
                    <div className="main-table">
                        <div className="tab-row tab-5 title-table">
                            <div className="title">Pos.</div>
                            <div className="title">Nombre (Gang)</div>
                            <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('Mensual')}>{selectedButton === 'Mensual' ? 'Puntos' : 'Puntos ▼'}</div>
                            <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('General')}>{selectedButton === 'General' ? 'Nivel' : 'Nivel ▼'}</div>
                            <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('Wines')}>{selectedButton === 'Wines' ? 'Vinos' : 'Vinos ▼'}</div>
                        </div>
                        <div className="tab-row tab-5">
                            {winegangData.users.map((user, index) => (
                                <Fragment key={index}>
                                    <div className="pos">{index + 1}</div>
                                    <button className="item" onClick={() => redirectToUserPage(user.id)}>{user.username}</button>
                                    <div className="item">{user.month}</div>
                                    <div className="item">{user.average.toFixed(2)}%</div>
                                    <div className="item">{user.wines}</div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                )}
                {winegangData.users && winegangData.users.length > 0 && Number(winegangData.admin_user_id) === Number(profileInfo.id) && (
                    <div className="main-table">
                        <div className="tab-row title-table">
                            <div className="title">Pos.</div>
                            <div className="title">Nombre</div>
                            <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('Mensual')}>{selectedButton === 'Mensual' ? 'Puntos' : 'Puntos ▼'}</div>
                            <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('General')}>{selectedButton === 'General' ? 'Nivel' : 'Nivel ▼'}</div>
                            <div className="title" style={{ cursor: 'pointer' }} onClick={() => handleButtonClick('Wines')}>{selectedButton === 'Wines' ? 'Vinos' : 'Vinos ▼'}</div>
                            <div className="title">Expulsar</div>
                        </div>
                        <div className="tab-row">
                            {winegangData.users.map((user, index) => (
                                <Fragment key={index}>
                                    <div className="pos">{index + 1}</div>
                                    <button className="item" onClick={() => redirectToUserPage(user.id)}>{user.username}</button>
                                    <div className="item">{user.month}</div>
                                    <div className="item">{user.average.toFixed(2)}%</div>
                                    <div className="item">{user.wines}</div>
                                    <div className={`exp ${user.id === Number(profileInfo.id) ? 'disabled-class' : ''}`}>
                                        <button onClick={() => deleteWinegangUser(user.id)}>X</button>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </div>
                )}
                {/* }
                <div className="second-buttons">
                    <button className={selectedButton === 'Mensual' ? 'selected-button' : ''} onClick={() => handleButtonClick('Mensual')}>Mensual</button>
                    <button className={selectedButton === 'General' ? 'selected-button' : ''} onClick={() => handleButtonClick('General')}>General</button>
                </div>
                */}
            </div>
            <TwoButtons />
            {isPopupVisible && (
                <Popup
                    text="¿Estás seguro de que quieres abandonar? Eres el lider de esta Winegang, si decides salir de ella el liderazgo pasará al siguiente miembro, y si eres el único, la Winegang desaparecerá"
                    onClose={closePopup}
                    onConfirm={leaveWinegangUser}
                />
            )}
        </div>
    );
}

function Popup({ text, onClose, onConfirm }) {
    return (
        <div className="custom-overlay">
            <div className="custom-popup">
                <p>{text}</p>
                <button onClick={onClose}>Cerrar</button>
                <button onClick={onConfirm}>Confirmar</button>
            </div>
        </div>
    );
}
